import React, { useState, useRef } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import HeroPage from "../components/hero-page";
import axios from "axios";
import Seo from "../components/seo"

const FormStyles = styled.form`
  .alert, .notice {
    margin-bottom: 1rem;
    border-radius: 6px;
    text-align: center;
    padding: 1rem;
    font-weight: bold;
  }

  .alert {
    color: red;
    border: 1px solid red;
    background-color: lightyellow;
  }

  .notice {
    color: green;
    border: 1px solid green;
    background-color: lightgreen;
  }
`;

const Connect = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: {
          eq: "32951057_10156410961762774_268419281153163264_o.jpg"
        }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      opengraphImage: file(relativePath: { eq: "32951057_10156410961762774_268419281153163264_o.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }

    }
  `);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const formBtnRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    formBtnRef.current.disabled = true;
    formBtnRef.current.value = "Sending...";
    setShowAlert(false)
    setShowNotice(false)

    try {
      await axios.post("api/sendmail", { name, email, message });

      setName("");
      setEmail("");
      setMessage("");
      setAlertMessage("Form sent successfully!");
      setShowNotice(true);
      setTimeout(() => {
        setShowNotice(false);
      }, 5000)
    } catch (error) {
      setAlertMessage(error.response.data);
      setShowAlert(true);
    }

    formBtnRef.current.disabled = false;
    formBtnRef.current.value = "Submit";
  };

  return (
    <>
      <Seo
        title="Connect with PA Six"
        description="At Discover PA Six we are dedicated to helping you discover what makes this region so great."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Connect with PA Six"
        caption="Olmsted Manor, McKean County"
        position="40%"
      />

      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Get in touch with us.</h2>
            <p>
              Do you have any questions or comments about the PA Six region?
            </p>
          </div>
          <div className="intro-text">
            <p>
              At Discover PA Six we are dedicated to helping you discover what
              makes this region so great.
            </p>
            <p>
              If you have any questions about our counties, or suggestions on
              how to improve our website, feel free to reach out with this form.
              We welcome your feedback.
            </p>
          </div>
        </section>

        <section>
          <FormStyles className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div>
                <label htmlFor="name">
                  Your name
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </label>
              </div>

              <div>
                <label htmlFor="email">
                  Your email
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
              </div>
            </div>

            <div className="form-row row-wide">
              <label>
                Your comments
                <textarea
                  name="message"
                  id="message"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                >
                  {" "}
                </textarea>
              </label>
            </div>

            {showAlert && <div className="alert">{alertMessage}</div>}
            {showNotice && <div className="notice">{alertMessage}</div>}

            <div className="form-row row-wide-centered">
              <input
                className="button button-brown"
                type="submit"
                value="Submit"
                ref={formBtnRef}
              />
            </div>
          </FormStyles>
        </section>
      </main>
    </>
  );
};

export default Connect;
